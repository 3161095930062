.rs-picker-toggle .rs-btn .rs-btn-default .rs-btn-lg {
    background-color: #fff !important;
    padding: 15px !important;
    padding-bottom: 10px !important;
    
}

.rs-picker-toggle-textbox{
    background-color: #fff !important;
}

.rs-picker-date.rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle, .rs-picker-daterange.rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle{
    border: 0px !important;
    box-shadow: none !important;
}

.rs-picker-default .rs-btn, .rs-picker-default .rs-picker-toggle, .rs-picker-input .rs-btn, .rs-picker-input .rs-picker-toggle{
    background-color: #fff !important;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value{
    color: #65748b
}

.rs-picker-default .rs-picker-toggle.rs-btn-lg{
    padding: 15px !important
}

.rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean, .rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean{
    margin-top: 5px !important;
    font-size: 16px !important;
    color: #6b7280
}

.rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret, .rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret{
    margin-top: 5px !important;
    font-size: 16px !important;
    color: #6b7280
}